<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <v-row align="center">
      <v-col
        v-for="cooperation in cooperations"
        :key="cooperation.title"
        xl="3"
        lg="4"
        md="6"
        sm="6"
        cols="12"
      >
        <v-card>
          <v-img
            v-if="cooperation.image"
            :src="cooperation.image"
            max-height="150"
          ></v-img>
          <v-card-title>{{ cooperation.title }}</v-card-title>
          <v-card-subtitle>{{ cooperation.subtitle }}</v-card-subtitle>

          <v-card-text>{{ cooperation.text }}</v-card-text>

          <v-card-actions class="justify-end">
            <v-btn
              color="accent"
              text
              class="float-right"
              :href="cooperation.href"
              target="_blank"
            >
              {{ $t("more") }}
              <v-icon class="ml-1">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "cooperations",
  components: {
    Breadcrumbs
  },
  data: () => ({
    cooperations: [
      {
        title: "GKV-Selbsthilfeförderung",
        subtitle: "Fördergeber",
        text:
          "Für das Jahr 2020 hat unsere Selbsthilfegruppe eine Förderung bekommen.",
        href: "http://www.gkv-selbsthilfefoerderung-he.de/"
      },
      {
        title: "ZiBB",
        subtitle: "Zentrum für interkulturelle Bildung und Begegnung Gießen",
        text:
          "Seit dem entstehen unserer Selbsthilfegruppe dürfen wir die Räume des ZiBBs für unsere Treffen verwenden.",
        href: "http://zibb-gi.de/"
      }
    ]
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("cooperations"),
          disabled: true
        }
      ];
    }
  }
};
</script>
